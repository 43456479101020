import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/colors'; 

const SuggestedPromptsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
  max-width: 1000px;
  width: 100%;
`;

const PromptRow = styled.div`
  display: flex;
  justify-content: ${props => props.center ? 'center' : 'space-between'};
  gap: 20px;
  flex-wrap: wrap;
`;

const PromptButton = styled.button`
  background-color: ${colors.secondary};
  color: ${colors.text};
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: clamp(12px, 2vw, 14px);
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: clamp(80px, 15vw, 100px);
  width: clamp(200px, calc(25% - 15px), 230px);
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;

  &:hover {
    background-color: ${colors.primaryLight};
    color: ${colors.textLight};
  }

  @media (max-width: 768px) {
    width: calc(50% - 10px);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const suggestedPrompts = [
  "What is the average cost per square foot for building a custom home in California?",
  "How to balance residential and commercial spaces in a mixed-use development to maximize ROI?",
  "What are the key necessary updates to transform an underutilized office building for increased occupancy?",
  "What are the top 5 strategies for commercial real estate owners to thrive during an economic downturn?",
  "What design interventions can stimulate economic vitality in struggling downtown San Francisco and transforming it into a vibrant community?",
  "Which regions in the US present the most favorable market conditions for developing a mixed-use project in today's market?",
  "What are the main critical factors to consider when developing a hyperscale greenfield data center?"
];

function SuggestedPrompts({ setInput }) {
  const handlePromptClick = (prompt) => {
    setInput(prompt);
  };

  return (
    <SuggestedPromptsContainer>
      <PromptRow>
        {suggestedPrompts.slice(0, 4).map((prompt, index) => (
          <PromptButton key={index} onClick={() => handlePromptClick(prompt)}>
            {prompt}
          </PromptButton>
        ))}
      </PromptRow>
      <PromptRow center>
        {suggestedPrompts.slice(4).map((prompt, index) => (
          <PromptButton key={index + 4} onClick={() => handlePromptClick(prompt)}>
            {prompt}
          </PromptButton>
        ))}
      </PromptRow>
    </SuggestedPromptsContainer>
  );
}

export default SuggestedPrompts;