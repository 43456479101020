// src/components/FormattedMessage.js
import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/colors';

const MessageContainer = styled.div`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Arial, sans-serif;
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
`;

const Header = styled.h3`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.accent};  // This will now use the new accent color
  font-weight: bold;
  font-size: 1.2em;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
`;

const BoldColoredText = styled.span`
  font-weight: bold;
  color: ${colors.accent};
`;

const FormattedMessage = ({ text }) => {
  const formatText = (text) => {
    const paragraphs = text.split('\n\n');
    return paragraphs.map((paragraph, index) => {
      const lines = paragraph.split('\n');
      return (
        <Paragraph key={index}>
          {lines.map((line, lineIndex) => {
            if (line.match(/^[A-Z\s]+:/)) {
              const [heading, ...content] = line.split(':');
              return (
                <React.Fragment key={lineIndex}>
                  <Header>{heading}:</Header>
                  {formatLine(content.join(':'))}
                  {lineIndex < lines.length - 1 && <br />}
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={lineIndex}>
                {formatLine(line)}
                {lineIndex < lines.length - 1 && <br />}
              </React.Fragment>
            );
          })}
        </Paragraph>
      );
    });
  };

  const formatLine = (line) => {
    const parts = line.split(/(\*\*.*?\*\*)/);
    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <BoldColoredText key={index}>{part.slice(2, -2)}</BoldColoredText>;
      }
      return part;
    });
  };

  return <MessageContainer>{formatText(text)}</MessageContainer>;
};

export default FormattedMessage;