import React, { useState } from 'react';
import styled from 'styled-components';
import ChatBox from './components/ChatBox';
import SuggestedPrompts from './components/SuggestedPrompts';
import { colors } from './styles/colors';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #38383B;
  color: #DFE0E1;
  font-family: Arial, sans-serif;
`;

const Header = styled.header`
  padding: 2rem;
  text-align: center;
`;

const Logo = styled.img`
  height: 120px;
  width: auto;
`;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 1rem;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  padding: 0 5%;
`;

const Disclaimer = styled.p`
  font-family: Arial, sans-serif;
  font-size: 12px;
  color: ${colors.secondary};
  text-align: left;
  max-width: 90%;
`;

function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const addMessage = (message) => {
    setMessages(prevMessages => [...prevMessages, message]);
  };

  return (
    <AppContainer>
      <Header>
        <Logo src="/Artaa-Logo-Round-Red-R (2).png" alt="ARTAA Logo" />
      </Header>
      <MainContent>
        <SuggestedPrompts setInput={setInput} />
        <ChatBox 
          messages={messages} 
          addMessage={addMessage} 
          input={input} 
          setInput={setInput} 
        />
      </MainContent>
      <DisclaimerWrapper>
        <Disclaimer>
          The information provided by ARTAA® Copilot is for general informational purposes only, may contain errors and should not be considered legal, financial, or professional advice. Consult with a qualified professional before making decisions based on this information. Use of this assistant does not create a client-professional relationship, and ARTAA® assumes no liability for actions taken based on this information.
        </Disclaimer>
      </DisclaimerWrapper>
    </AppContainer>
  );
}

export default App;